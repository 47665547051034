var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":{
        name: 'tune',
        params: {
            settingID: _vm.settingID,
            tuneID: _vm.setting.tune_id,
            displayName: _vm.displayName,
        },
    }}},[_c('v-container',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"click":_vm.addToHistory}},[_c('v-row',{staticClass:"pt-1 pb-0"},[_c('v-col',{staticClass:"py-0"},[_c('h2',[_vm._v(_vm._s(_vm.name))])])],1),_c('v-row',{staticClass:"pb-2 pt-0"},[_c('v-col',{staticClass:"py-0 descriptor"},[_vm._v(" "+_vm._s(_vm.descriptor)+" ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.score !== null),expression:"score !== null"}],staticClass:"py-0 text-right score",style:(("color: " + _vm.scoreColour + ";"))},[_vm._v(" "+_vm._s(_vm.scoreLabel)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }